const action = {
    sign_in: 'Iniciar sesión',
    continue: 'Continuar',
    create_account: 'Crear una cuenta',
    create_account_without_linking: 'Crear una cuenta sin vinculación',
    create: 'Crear',
    enter_passcode: 'Ingresar código de verificación',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save_password: 'Guardar contraseña',
    bind: 'Vincular con {{address}}',
    bind_and_continue: 'Vincular y continuar',
    back: 'Regresar',
    nav_back: 'Atrás',
    agree: 'Aceptar',
    got_it: 'Entendido',
    sign_in_with: 'Continuar con {{name}}',
    forgot_password: '¿Olvidaste tu contraseña?',
    switch_to: 'Cambiar a {{method}}',
    sign_in_via_passcode: 'Iniciar sesión con código de verificación',
    sign_in_via_password: 'Iniciar sesión con contraseña',
    change: 'Cambiar {{method}}',
    link_another_email: 'Vincular otro correo electrónico',
    link_another_phone: 'Vincular otro número de teléfono',
    link_another_email_or_phone: 'Vincular otro correo electrónico o número de teléfono',
    show_password: 'Mostrar contraseña',
};
export default Object.freeze(action);

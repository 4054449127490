@use '@/scss/underscore' as _;

.tag {
  @include _.section-head-2;
  display: inline-block;
  background-color: var(--color-specific-tag-upsell);
  border-radius: 10px;
  padding: 0 _.unit(1) 0 _.unit(1.5);
  color: var(--color-white);
  margin: 0 _.unit(1);
}

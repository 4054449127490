const roles = {
    page_title: 'ロール',
    title: 'ロール',
    subtitle: 'ロールには、ユーザーが実行できるアクションを決定する権限が含まれます。RBACは、特定のアクションのためにリソースにアクセスするためにユーザーに権限を付与するために、ロールを使用します。',
    create: 'ロールを作成する',
    role_name: '役割名',
    role_type: '役割タイプ',
    type_user: 'ユーザーの役割',
    type_machine_to_machine: 'マシン対マシンアプリの役割',
    role_description: '説明',
    role_name_placeholder: 'ロールの名前を入力してください',
    role_description_placeholder: 'ロールの説明を入力してください',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: '権限の割り当て',
    create_role_title: 'ロールを作成する',
    create_role_description: 'アプリケーションのロールを作成して管理します。ロールには権限のコレクションが含まれ、ユーザーに割り当てることができます。',
    create_role_button: 'ロールを作成する',
    role_created: '{{name}}ロールが正常に作成されました。',
    search: 'ロール名、説明、またはIDで検索',
    placeholder_title: 'ロール',
    placeholder_description: 'ロールは、ユーザーに割り当てられる権限のグループです。ロールを作成する前に、まず権限を追加してください。',
};
export default Object.freeze(roles);

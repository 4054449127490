@use '@/scss/underscore' as _;

.languageDetails {
  display: flex;
  flex-direction: column;

  .title {
    flex-shrink: 0;
    padding: _.unit(6) _.unit(5);
    font: var(--font-title-1);
    color: var(--color-text);
    display: flex;
    justify-content: space-between;

    .languageInfo {
      display: flex;
      align-items: center;

      > span {
        margin: _.unit(2);
        font: var(--font-body-2);
        color: var(--color-text-secondary);
      }
    }
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--color-divider);
    overflow: hidden;

    .tableWrapper {
      padding: 0;
    }

    .content {
      flex: 1;

      .customValuesColumn {
        display: flex;
        align-items: center;
      }

      .clearButton {
        margin-left: _.unit(1);
      }

      .clearIcon {
        width: 16px;
        height: 16px;
      }

      .sectionTitle {
        @include _.section-head-2;
        color: var(--color-neutral-variant-60);
        background-color: var(--color-layer-light);
      }

      .sectionDataKey {
        padding: _.unit(4) _.unit(5);
        font: var(--font-body-2);
        color: var(--color-text);
      }

      .sectionBuiltInText {
        padding: _.unit(2) _.unit(3);
        border-radius: 6px;
        border: 1px solid var(--color-border);
        color: var(--color-text);
        background: var(--color-layer-2);
      }

      .inputCell {
        position: relative;
      }

      .sectionInputArea {
        position: absolute;
        inset: _.unit(2) _.unit(5);
      }

      table {
        border: none;

        thead {
          tr > th {
            padding: _.unit(1) _.unit(5);
            font: var(--font-label-2);
            color: var(--color-text);
            background-color: var(--color-layer-1);
            border-bottom: 1px solid var(--color-divider);
          }
        }

        tbody {
          tr {
            td {
              padding: _.unit(2) _.unit(5);
              border: none;
              word-wrap: break-word;
            }
          }
        }
      }
    }

    .footer {
      flex-shrink: 0;
      border-top: 1px solid var(--color-divider);
      height: 85px;
      display: flex;
      flex-direction: row-reverse;
      padding: _.unit(5);
    }
  }
}

const get_started = {
    page_title: 'はじめに',
    title: 'あなたの成功を手助けする探索',
    subtitle: 'Logtoの価値を迅速に把握するための数つの方法',
    develop: {
        title: 'Develop: 5分の時間を使ってアプリを統合してみましょう',
    },
    customize: {
        title: 'Customize: 素晴らしいサインイン体験を提供する',
        preview: {
            title: 'あなたがカスタマイズしたサインイン体験のライブプレビューをチェックしましょう',
            subtitle: 'Logtoのサインイン体験を試して、その使い方を確認してください',
        },
        connector: {
            title: 'さらなるコネクタを追加して、さまざまなソーシャルサインイン方式をサポートしましょう',
            subtitle: 'パスワードレスサインインを試して、顧客に安全かつ摩擦のない体験を提供しましょう',
        },
        continue_customizing: 'カスタマイズを続ける',
        try_now: '今すぐ試す',
        add_more: 'もっと追加する',
    },
    secure: {
        title: 'Secure: リソースを保護する',
    },
    manage: {
        title: 'Manage: 製品とユーザーのアクセス制御を定義する',
        rbac: {
            title: 'ロールベースのアクセス制御を追加して、リソースを保護する',
            subtitle: '多様なユースケースに対してスケーラブルなロールの認可によりリソースを制御します',
        },
        create_roles: 'ロールを作成する',
    },
    view_all: 'すべての内容を表示 →',
};
export default Object.freeze(get_started);

const tabs = {
    get_started: '開始上手',
    dashboard: '儀表板',
    applications: '全部應用',
    api_resources: 'API 資源',
    sign_in_experience: '登錄體驗',
    connectors: '連接器',
    webhooks: 'Webhooks',
    users: '用戶管理',
    audit_logs: '審計日誌',
    roles: '角色',
    docs: '文檔',
    tenant_settings: '設置',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);

@use '@/scss/underscore' as _;

.empty {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

.connectorName {
  display: flex;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    border-radius: _.unit(2);
    flex-shrink: 0;
  }

  .name {
    margin-left: _.unit(3);
  }
}

.connectorId {
  display: flex;
  align-items: center;
  font: var(--font-body-2);
  font-family: 'Roboto Mono', monospace;
  line-height: 32px;

  span {
    display: inline-block;
    @include _.text-ellipsis;
  }
}

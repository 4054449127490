const roles = {
    page_title: 'Rollen',
    title: 'Rollen',
    subtitle: 'Rollen beinhalten Berechtigungen, die bestimmen, was ein Benutzer tun kann. RBAC verwendet Rollen, um Benutzern Zugriff auf Ressourcen für bestimmte Aktionen zu geben.',
    create: 'Rolle erstellen',
    role_name: 'Rollenname',
    role_type: 'Rollenart',
    type_user: 'Benutzerrolle',
    type_machine_to_machine: 'Maschinen-zu-Maschinen-App-Rolle',
    role_description: 'Beschreibung',
    role_name_placeholder: 'Geben Sie Ihren Rollennamen ein',
    role_description_placeholder: 'Geben Sie Ihre Rollenbeschreibung ein',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: 'Berechtigungen zuweisen',
    create_role_title: 'Rolle erstellen',
    create_role_description: 'Erstellen und verwalten Sie Rollen für Ihre Anwendungen. Rollen enthalten Sammlungen von Berechtigungen und können Benutzern zugewiesen werden.',
    create_role_button: 'Rolle erstellen',
    role_created: 'Die Rolle {{name}} wurde erfolgreich erstellt.',
    search: 'Nach Rollennamen, Beschreibung oder ID suchen',
    placeholder_title: 'Rollen',
    placeholder_description: 'Rollen sind eine Gruppierung von Berechtigungen, die Benutzern zugewiesen werden können. Stellen Sie sicher, dass Sie zuerst Berechtigungen hinzufügen, bevor Sie Rollen erstellen.',
};
export default Object.freeze(roles);

const logs = {
    page_title: 'Audit Logs',
    title: 'Audit Logs',
    subtitle: 'Visualize os dados de log dos eventos de autenticação feitos por seu administrador e usuários',
    event: 'Evento',
    user: 'Usuário',
    application: 'Aplicativo',
    time: 'Tempo',
    filter_by: 'Filtrar por',
};
export default Object.freeze(logs);

@use '@/scss/underscore' as _;

.factorLabel {
  display: flex;
  flex-direction: column;
  gap: _.unit(1);
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.factorTitle {
  display: flex;
  align-items: center;
  color: var(--color-text);

  .factorIcon {
    color: var(--color-text-secondary);
    margin-right: _.unit(3);
  }
}

const tabs = {
    get_started: '开始上手',
    dashboard: '仪表盘',
    applications: '全部应用',
    api_resources: 'API 资源',
    sign_in_experience: '登录体验',
    connectors: '连接器',
    webhooks: 'Webhooks',
    users: '用户管理',
    audit_logs: '审计日志',
    roles: '角色',
    docs: '文档',
    tenant_settings: '设置',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);

@use '@/scss/underscore' as _;

.item {
  margin-left: _.unit(4);
  font: var(--font-body-2);

  &.withIcon {
    list-style-type: none;
    margin-left: unset;
  }

  .itemContent {
    display: flex;
    align-items: center;
    gap: _.unit(2);

    .icon {
      width: 16px;
      height: 16px;

      &.notCapable {
        color: var(--color-error);
      }

      &.capable {
        color: var(--color-on-success-container);
      }
    }

    .lineThrough {
      text-decoration: line-through;
    }
  }
}

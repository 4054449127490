const get_started = {
    page_title: 'Commencer',
    title: 'Quelque chose à explorer pour vous aider à réussir',
    subtitle: 'Quelques choses que vous pouvez faire pour obtenir rapidement de la valeur de Logto',
    develop: {
        title: 'Développer: Prenez 5 minutes pour intégrer votre application',
    },
    customize: {
        title: 'Personnaliser: Offrez une excellente expérience de connexion',
        preview: {
            title: "Vérifiez l'aperçu en direct de l'expérience de connexion que vous venez de personnaliser",
            subtitle: "Essayez dès maintenant l'expérience de connexion de Logto pour voir comment cela fonctionne",
        },
        connector: {
            title: 'Ajoutez plus de connecteurs pour prendre en charge plus de méthodes de connexion social',
            subtitle: 'Essayez la connexion sans mot de passe et activez une expérience sécurisée et sans friction pour vos clients',
        },
        continue_customizing: 'Continuer la personnalisation',
        try_now: 'Essayez maintenant',
        add_more: 'Ajouter plus',
    },
    secure: {
        title: 'Sécurité: Protégez vos ressources',
    },
    manage: {
        title: "Gérer: Définir le contrôle d'accès pour votre produit et vos utilisateurs",
        rbac: {
            title: "Ajoutez un contrôle d'accès basé sur les rôles pour protéger vos ressources",
            subtitle: "Contrôlez vos ressources grâce à une autorisation de rôle évolutive pour des cas d'utilisation divers",
        },
        create_roles: 'Créer des rôles',
    },
    view_all: 'Tout afficher →',
};
export default Object.freeze(get_started);

@use '@/scss/underscore' as _;

.container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
}

.notes {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: _.unit(3);
}

const roles = {
    page_title: 'Role',
    title: 'Role',
    subtitle: 'Role zawiera uprawnienia określające, co użytkownik może robić. RBAC wykorzystuje role do udostępniania użytkownikom zasobów do określonych działań.',
    create: 'Utwórz rolę',
    role_name: 'Nazwa roli',
    role_type: 'Typ roli',
    type_user: 'Rola użytkownika',
    type_machine_to_machine: 'Rola aplikacji Machine-to-Machine',
    role_description: 'Opis',
    role_name_placeholder: 'Wprowadź nazwę swojej roli',
    role_description_placeholder: 'Wprowadź opis swojej roli',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: 'Przypisz uprawnienia',
    create_role_title: 'Utwórz rolę',
    create_role_description: 'Utwórz i zarządzaj rolami dla swoich aplikacji. Role zawierają zbiory uprawnień i mogą być przypisywane użytkownikom.',
    create_role_button: 'Utwórz rolę',
    role_created: 'Rola {{name}} została pomyślnie utworzona.',
    search: 'Wyszukaj po nazwie roli, opisie lub identyfikatorze',
    placeholder_title: 'Role',
    placeholder_description: 'Role są grupowaniem uprawnień, które mogą być przypisywane użytkownikom. Upewnij się, że najpierw dodasz uprawnienie, zanim utworzysz role.',
};
export default Object.freeze(roles);

@use '@/scss/underscore' as _;

.policyLabel {
  font: var(--font-body-2);
  display: flex;
  flex-direction: column;
  gap: _.unit(1);
}

.description {
  color: var(--color-text-secondary);
}

@use '@/scss/underscore' as _;

.withTable {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: _.unit(6) _.unit(8);

  .info {
    .name {
      font: var(--font-title-1);
      color: var(--color-text);
    }

    .meta {
      display: flex;
      align-items: center;

      .idText {
        font: var(--font-label-2);
        color: var(--color-text-secondary);
        margin-right: _.unit(1);
      }
    }
  }

  .moreIcon {
    color: var(--color-text-secondary);
  }
}

const tabs = {
    get_started: 'Rozpocznij',
    dashboard: 'Panel',
    applications: 'Aplikacje',
    api_resources: 'Zasoby API',
    sign_in_experience: 'Doświadczenie przy logowaniu',
    connectors: 'Konnektory',
    webhooks: 'Webhooki',
    users: 'Zarządzanie użytkownikami',
    audit_logs: 'Dzienniki audytu',
    roles: 'Role',
    docs: 'Dokumentacja',
    tenant_settings: 'Ustawienia',
    /** UNTRANSLATED */
    mfa: 'Multi-factor auth',
};
export default Object.freeze(tabs);

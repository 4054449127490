const roles = {
    page_title: '角色',
    title: '角色',
    subtitle: 'RBAC 是一種訪問控制方法，它使用角色來決定用戶可以做什麼事情，包括授予用戶訪問特定資源的權限。',
    create: '創建角色',
    role_name: '角色名稱',
    role_type: '角色類型',
    type_user: '用戶角色',
    type_machine_to_machine: '機器到機器應用程式角色',
    role_description: '描述',
    role_name_placeholder: '輸入你的角色名稱',
    role_description_placeholder: '輸入你的角色描述',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: '分配權限',
    create_role_title: '創建角色',
    create_role_description: '為你的應用程式創建和管理角色。角色包含權限集合，並可以分配給用戶。',
    create_role_button: '創建角色',
    role_created: '角色 {{name}} 已成功創建。',
    search: '按角色名稱、描述或 ID 搜索',
    placeholder_title: '角色',
    placeholder_description: '角色是可以分配給用戶的權限分組。在創建角色之前，請確保先添加權限。',
};
export default Object.freeze(roles);

const guide = {
    start_building: 'Começar a construir',
    get_started: 'Iniciar',
    categories: {
        featured: 'Popular e para você',
        Traditional: 'Aplicativo web tradicional',
        SPA: 'Aplicativo de página única',
        Native: 'Nativo',
        MachineToMachine: 'Máquina a máquina',
    },
    filter: {
        title: 'Filtrar framework',
        placeholder: 'Pesquisar framework',
    },
    checkout_tutorial: 'Verificar tutorial {{name}}',
    do_not_need_tutorial: 'Se você não precisa de um tutorial, você pode continuar sem um guia de framework',
    finish_and_done: 'Concluir e finalizado',
    cannot_find_guide: 'Não é possível encontrar seu guia?',
    describe_guide_looking_for: 'Descreva o guia que você está procurando',
    request_guide_successfully: 'Sua solicitação foi enviada com sucesso. Obrigado!',
    app: {
        select_framework_or_tutorial: 'Selecionar um framework ou tutorial',
        guide_modal_title: 'Iniciar com SDK e guias',
        modal_subtitle: 'Inicie seu processo de desenvolvimento de aplicativo com nosso SDK pré-construído e tutoriais.',
        select_a_framework: 'Selecionar um framework',
        continue_without_framework: 'Criar aplicativo sem framework',
        describe_guide_looking_for_placeholder: 'Por exemplo, eu quero integrar o Logto ao meu aplicativo Angular.',
    },
    api: {
        modal_title: 'Iniciar com tutoriais',
        modal_subtitle: 'Inicie seu processo de desenvolvimento de aplicativo com nossos tutoriais pré-construídos.',
        select_a_tutorial: 'Selecionar um tutorial',
        continue_without_tutorial: 'Continuar sem tutorial',
        describe_guide_looking_for_placeholder: 'Por exemplo, eu quero proteger minha API usando Deno.',
    },
};
export default Object.freeze(guide);

// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { hookEventGuard, hookEventsGuard, hookConfigGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    name: z.string().min(1).max(256).optional(),
    event: hookEventGuard.nullable().optional(),
    events: hookEventsGuard.optional(),
    config: hookConfigGuard,
    signingKey: z.string().min(1).max(64).optional(),
    enabled: z.boolean().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    name: z.string().max(256),
    event: hookEventGuard.nullable(),
    events: hookEventsGuard,
    config: hookConfigGuard,
    signingKey: z.string().max(64),
    enabled: z.boolean(),
    createdAt: z.number(),
});
export const Hooks = Object.freeze({
    table: 'hooks',
    tableSingular: 'hook',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        name: 'name',
        event: 'event',
        events: 'events',
        config: 'config',
        signingKey: 'signing_key',
        enabled: 'enabled',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'name',
        'event',
        'events',
        'config',
        'signingKey',
        'enabled',
        'createdAt',
    ],
    createGuard,
    guard,
});

const roles = {
    page_title: 'Roller',
    title: 'Roller',
    subtitle: 'Roller, bir kullanıcının ne yapabileceğini belirleyen izinleri içerir. RBAC, kullanıcılara belirli işlemler için kaynaklara erişim vermek için roller kullanır.',
    create: 'Rol Oluştur',
    role_name: 'Rol adı',
    role_type: 'Rol tipi',
    type_user: 'Kullanıcı rolü',
    type_machine_to_machine: 'Makine-makine uygulama rolü',
    role_description: 'Açıklama',
    role_name_placeholder: 'Rol adınızı girin',
    role_description_placeholder: 'Rol açıklamanızı girin',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: 'İzinleri Ata',
    create_role_title: 'Rol Oluştur',
    create_role_description: 'Uygulamalarınız için rolleri oluşturun ve yönetin. Roller, izin koleksiyonlarını içerir ve kullanıcılara atanabilir.',
    create_role_button: 'Rol Oluştur',
    role_created: '{{name}} rolü başarıyla oluşturuldu.',
    search: 'Rol adı, açıklama veya kimlik numarasına göre arama yapın',
    placeholder_title: 'Roller',
    placeholder_description: 'Roller, kullanıcılara atanabilecek izinlerin gruplandırmasıdır. Rolleri oluşturmadan önce izin eklediğinizden emin olun.',
};
export default Object.freeze(roles);

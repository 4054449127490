const quota_item = {
    tenant_limit: {
        name: 'Tenants',
        limited: '{{count, number}} locataire',
        limited_other: '{{count, number}} locataires',
        unlimited: 'Illimité locataires',
        not_eligible: 'Supprimez vos locataires',
    },
    mau_limit: {
        name: 'Utilisateurs actifs mensuels',
        limited: '{{count, number}} MAU',
        unlimited: 'Illimité MAU',
        not_eligible: 'Supprimez tous vos utilisateurs',
    },
    applications_limit: {
        name: 'Applications',
        limited: '{{count, number}} application',
        limited_other: '{{count, number}} applications',
        unlimited: 'Illimité applications',
        not_eligible: 'Supprimez vos applications',
    },
    machine_to_machine_limit: {
        name: 'Machine to machine',
        limited: '{{count, number}} application machine à machine',
        limited_other: '{{count, number}} applications machine à machine',
        unlimited: 'Illimité applications machine à machine',
        not_eligible: 'Supprimez vos applications machine à machine',
    },
    resources_limit: {
        name: 'Ressources API',
        limited: '{{count, number}} ressource API',
        limited_other: '{{count, number}} ressources API',
        unlimited: 'Illimité ressources API',
        not_eligible: 'Supprimez vos ressources API',
    },
    scopes_per_resource_limit: {
        name: 'Permissions de ressource',
        limited: '{{count, number}} autorisation par ressource',
        limited_other: '{{count, number}} autorisations par ressource',
        unlimited: 'Illimité autorisation par ressource',
        not_eligible: 'Supprimez vos permissions de ressource',
    },
    custom_domain_enabled: {
        name: 'Domaine personnalisé',
        limited: 'Domaine personnalisé',
        unlimited: 'Domaine personnalisé',
        not_eligible: 'Supprimez votre domaine personnalisé',
    },
    omni_sign_in_enabled: {
        name: 'Connexion omni',
        limited: 'Connexion omni',
        unlimited: 'Connexion omni',
        not_eligible: 'Désactivez votre connexion omni',
    },
    built_in_email_connector_enabled: {
        name: 'Connecteur de messagerie intégré',
        limited: 'Connecteur de messagerie intégré',
        unlimited: 'Connecteur de messagerie intégré',
        not_eligible: 'Supprimez votre connecteur de messagerie intégré',
    },
    social_connectors_limit: {
        name: 'Connecteurs sociaux',
        limited: '{{count, number}} connecteur social',
        limited_other: '{{count, number}} connecteurs sociaux',
        unlimited: 'Illimité connecteurs sociaux',
        not_eligible: 'Supprimez vos connecteurs sociaux',
    },
    standard_connectors_limit: {
        name: 'Connecteurs standards gratuits',
        limited: '{{count, number}} connecteur standard gratuit',
        limited_other: '{{count, number}} connecteurs standards gratuits',
        unlimited: 'Illimité connecteurs standards',
        not_eligible: 'Supprimez vos connecteurs standards',
    },
    roles_limit: {
        name: 'Rôles',
        limited: '{{count, number}} rôle',
        limited_other: '{{count, number}} rôles',
        unlimited: 'Illimité rôles',
        not_eligible: 'Supprimez vos rôles',
    },
    scopes_per_role_limit: {
        name: 'Permissions de rôle',
        limited: '{{count, number}} permission par rôle',
        limited_other: '{{count, number}} permissions par rôle',
        unlimited: 'Illimité permission par rôle',
        not_eligible: 'Supprimez vos permissions de rôle',
    },
    hooks_limit: {
        name: 'Webhooks',
        limited: '{{count, number}} webhook',
        limited_other: '{{count, number}} webhooks',
        unlimited: 'Webhooks illimités',
        not_eligible: 'Supprimez vos webhooks',
    },
    audit_logs_retention_days: {
        name: "Conservation des journaux d'audit",
        limited: "Conservation des journaux d'audit: {{count, number}} jour",
        limited_other: "Conservation des journaux d'audit: {{count, number}} jours",
        unlimited: 'Jours illimités',
        not_eligible: "Pas de journalisation d'audit",
    },
    community_support_enabled: {
        name: 'Support de la communauté',
        limited: 'Support de la communauté',
        unlimited: 'Support de la communauté',
        not_eligible: 'Pas de support de la communauté',
    },
    customer_ticket_support: {
        name: 'Support client de ticket',
        limited: '{{count, number}} heure de support client de ticket',
        limited_other: '{{count, number}} heures de support client de ticket',
        unlimited: 'Support client de ticket',
        not_eligible: 'Pas de support client de ticket',
    },
    mfa_enabled: {
        name: 'MFA',
        limited: 'MFA',
        unlimited: 'MFA',
        not_eligible: 'Désactivez votre MFA',
    },
};
export default Object.freeze(quota_item);

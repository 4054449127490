const log_details = {
    page_title: 'Audit Log Details',
    back_to_logs: 'Zurück zu Audit Logs',
    back_to: 'Zurück zu {{name}}',
    success: 'Erfolgreich',
    failed: 'Fehlgeschlagen',
    event_key: 'Event Schlüssel',
    application: 'Anwendung',
    ip_address: 'IP-Adresse',
    user: 'Benutzer',
    log_id: 'Log-ID',
    time: 'Zeit',
    user_agent: 'User-Agent',
    tab_details: 'Details',
    raw_data: 'Rohe Daten',
};
export default Object.freeze(log_details);

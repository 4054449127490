const action = {
    sign_in: '로그인',
    continue: '계속',
    create_account: '계정 생성',
    create_account_without_linking: '연동 없이 계정 생성하기',
    create: '생성',
    enter_passcode: '비밀번호 입력',
    confirm: '확인',
    cancel: '취소',
    save_password: '저장',
    bind: '{{address}}로 연동',
    bind_and_continue: '연동하고 계속하기',
    back: '뒤로 가기',
    nav_back: '뒤로',
    agree: '동의',
    got_it: '알겠습니다',
    sign_in_with: '{{name}} 계속',
    forgot_password: '비밀번호를 잊어버리셨나요?',
    switch_to: '{{method}}로 전환',
    sign_in_via_passcode: '인증번호로 로그인',
    sign_in_via_password: '비밀번호로 로그인',
    change: '변경 {{method}}',
    link_another_email: '다른 이메일 연동',
    link_another_phone: '다른 전화번호 연동',
    link_another_email_or_phone: '다른 이메일 또는 전화번호 연동',
    show_password: '비밀번호 보기',
};
export default Object.freeze(action);

@use '@/scss/underscore' as _;

.tableEmptyWrapper {
  border-bottom: unset;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: _.unit(25);
  }
}

@use '@/scss/underscore' as _;

.container {
  position: relative;
  flex: 1;
  border-radius: 12px;
  border: 1px solid var(--color-divider);
  display: flex;
  flex-direction: column;
}

.planInfo {
  padding: _.unit(6);
  border-bottom: 1px solid var(--color-divider);

  > div:not(:first-child) {
    margin-top: _.unit(4);
  }

  .title {
    font: var(--font-headline-2);
  }

  .priceInfo {
    > div:not(:first-child) {
      margin-top: _.unit(1);
    }

    .priceLabel {
      font: var(--font-body-3);
      color: var(--color-text-secondary);
    }

    .price {
      font: var(--font-headline-3);
    }

    .unitPrices {
      margin-left: _.unit(3);
    }
  }

  .description {
    margin-top: _.unit(1);
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    height: 40px;
  }
}

.content {
  flex: 1;
  padding: _.unit(6);
  display: flex;
  flex-direction: column;

  .tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: _.unit(4);

    &.exceedFreeTenantsTip {
      font: var(--font-body-2);
      color: var(--color-text-secondary);
    }

    .link {
      font: var(--font-label-2);
      display: flex;
      align-items: center;
    }

    .linkIcon {
      width: 16px;
      height: 16px;
    }
  }

  .list {
    flex: 1;
    padding-bottom: _.unit(8);
  }
}

.mostPopularTag {
  position: absolute;
  border-radius: 4px 4px 0;
  font: var(--font-label-3);
  padding: _.unit(1.5) _.unit(2) _.unit(1.5) _.unit(2.5);
  color: var(--color-white);
  background-color: var(--color-specific-tag-upsell);
  right: -5px;
  top: _.unit(6);
  width: 64px;
  text-align: center;

  &::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    bottom: -3px;
    border-left: 4px solid var(--color-primary-60);
    border-bottom: 3px solid transparent;
  }
}

@use '@/scss/underscore' as _;

.container {
  > div:not(:first-child) {
    margin-top: _.unit(4);
  }
}

.upsellNotification {
  margin-top: _.unit(4);
}

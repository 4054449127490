// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { jsonObjectGuard } from './../foundations/index.js';
const createGuard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21).optional(),
    type: z.string().min(1).max(64),
    payload: jsonObjectGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21),
    type: z.string().min(1).max(64),
    payload: jsonObjectGuard,
    createdAt: z.number(),
});
export const ServiceLogs = Object.freeze({
    table: 'service_logs',
    tableSingular: 'service_log',
    fields: {
        id: 'id',
        tenantId: 'tenant_id',
        type: 'type',
        payload: 'payload',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'id',
        'tenantId',
        'type',
        'payload',
        'createdAt',
    ],
    createGuard,
    guard,
});

const guide = {
    start_building: 'プロジェクトを開始',
    get_started: 'はじめる',
    categories: {
        featured: '人気でおすすめ',
        Traditional: '伝統的な Web アプリ',
        SPA: 'シングルページアプリ',
        Native: 'ネイティブ',
        MachineToMachine: 'Machine-to-machine',
    },
    filter: {
        title: 'フレームワークを絞り込む',
        placeholder: 'フレームワークを検索',
    },
    checkout_tutorial: 'チュートリアルを確認: {{name}}',
    do_not_need_tutorial: 'もしチュートリアルが必要なければ、フレームワークガイドなしで続行できます',
    finish_and_done: '完了',
    cannot_find_guide: 'ガイドが見つかりません',
    describe_guide_looking_for: 'お探しのガイドの詳細を説明してください',
    request_guide_successfully: 'リクエストが送信されました。ありがとうございます！',
    app: {
        select_framework_or_tutorial: 'フレームワークまたはチュートリアルを選択',
        guide_modal_title: 'SDK とガイドで開始',
        modal_subtitle: '私たちの事前ビルドされた SDK とチュートリアルを使用してアプリ開発プロセスをスタートさせます。',
        select_a_framework: 'フレームワークを選択',
        continue_without_framework: 'フレームワークなしで作成',
        describe_guide_looking_for_placeholder: '例: Logto を Angular アプリに統合したい',
    },
    api: {
        modal_title: 'チュートリアルで開始',
        modal_subtitle: '前もってビルドされたチュートリアルでアプリ開発を加速します',
        select_a_tutorial: 'チュートリアルを選択',
        continue_without_tutorial: 'チュートリアルなしで続行',
        describe_guide_looking_for_placeholder: '例: API を deno で保護したい',
    },
};
export default Object.freeze(guide);

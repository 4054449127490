const action = {
    sign_in: 'Anmelden',
    continue: 'Weiter',
    create_account: 'Konto erstellen',
    create_account_without_linking: 'Erstelle ein Konto ohne Verknüpfung',
    create: 'Erstellen',
    enter_passcode: 'Bestätigungscode eingeben',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    save_password: 'Passwort speichern',
    bind: 'Mit {{address}} verknüpfen',
    bind_and_continue: 'Verknüpfen und weiter',
    back: 'Gehe zurück',
    nav_back: 'Zurück',
    agree: 'Zustimmen',
    got_it: 'Alles klar',
    sign_in_with: 'Mit {{name}} anmelden',
    forgot_password: 'Passwort vergessen?',
    switch_to: 'Zu {{method}} wechseln',
    sign_in_via_passcode: 'Mit Bestätigungscode anmelden',
    sign_in_via_password: 'Mit Passwort anmelden',
    change: '{{method}} ändern',
    link_another_email: 'Andere Email verknüpfen',
    link_another_phone: 'Andere Telefonnummer verknüpfen',
    link_another_email_or_phone: 'Andere Email oder Telefonnummer verknüpfen',
    show_password: 'Passwort anzeigen',
};
export default Object.freeze(action);

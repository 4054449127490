const action = {
    sign_in: '登录',
    continue: '继续',
    create_account: '注册',
    create_account_without_linking: '注册新的账号',
    create: '注册',
    enter_passcode: '输入验证码',
    confirm: '确认',
    cancel: '取消',
    save_password: '保存密码',
    bind: '绑定到 {{address}}',
    bind_and_continue: '绑定并继续',
    back: '返回',
    nav_back: '返回',
    agree: '同意',
    got_it: '知道了',
    sign_in_with: '通过 {{name}} 继续',
    forgot_password: '忘记密码？',
    switch_to: '切换到{{method}}',
    sign_in_via_passcode: '用验证码登录',
    sign_in_via_password: '密码登录',
    change: '更改{{method}}',
    link_another_email: '绑定其它邮箱',
    link_another_phone: '绑定其它手机',
    link_another_email_or_phone: '绑定邮箱或手机号',
    show_password: '显示密码',
};
export default Object.freeze(action);

@use '@/scss/underscore' as _;

.inlineNotification {
  padding: _.unit(3) _.unit(4);
  font: var(--font-body-2);
  display: flex;
  border-radius: 8px;
  align-items: center;
  gap: _.unit(3);

  &.shadow {
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-1);
  }

  .icon {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  .content {
    flex: 1;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  &.info {
    background: var(--color-info-container);

    .icon {
      color: var(--color-on-info-container);
    }
  }

  &.alert {
    background: var(--color-alert-container);

    .icon {
      color: var(--color-on-alert-container);
    }
  }

  &.success {
    background: var(--color-success-container);
  }

  &.error {
    background: var(--color-error-container);
  }
}

const roles = {
    page_title: 'Roles',
    title: 'Roles',
    subtitle: 'Los roles incluyen permisos que determinan lo que un usuario puede hacer. RBAC utiliza roles para dar acceso a recursos a los usuarios para acciones específicas.',
    create: 'Crear Rol',
    role_name: 'Nombre de rol',
    role_type: 'Tipo de rol',
    type_user: 'Rol de usuario',
    type_machine_to_machine: 'Rol de aplicación de máquina a máquina',
    role_description: 'Descripción',
    role_name_placeholder: 'Ingrese el nombre de su rol',
    role_description_placeholder: 'Ingrese la descripción de su rol',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: 'Asignar permisos',
    create_role_title: 'Crear Rol',
    create_role_description: 'Cree y administre roles para sus aplicaciones. Los roles contienen colecciones de permisos y pueden asignarse a los usuarios.',
    create_role_button: 'Crear Rol',
    role_created: 'El rol {{name}} se ha creado satisfactoriamente.',
    search: 'Buscar por nombre de rol, descripción o ID',
    placeholder_title: 'Roles',
    placeholder_description: 'Los roles son un grupo de permisos que se pueden asignar a los usuarios. Asegúrese de agregar permisos antes de crear roles.',
};
export default Object.freeze(roles);

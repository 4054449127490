const roles = {
    page_title: 'Rôles',
    title: 'Rôles',
    subtitle: "Les rôles incluent des autorisations qui déterminent ce qu'un utilisateur peut faire. RBAC utilise des rôles pour donner aux utilisateurs accès à des ressources pour des actions spécifiques.",
    create: 'Créer un rôle',
    role_name: 'Nom du rôle',
    role_type: 'Type de rôle',
    type_user: 'Rôle utilisateur',
    type_machine_to_machine: "Rôle d'application machine-à-machine",
    role_description: 'Description',
    role_name_placeholder: 'Entrez le nom de votre rôle',
    role_description_placeholder: 'Entrez la description de votre rôle',
    /** UNTRANSLATED */
    col_roles: 'Roles',
    /** UNTRANSLATED */
    col_type: 'Type',
    /** UNTRANSLATED */
    col_description: 'Description',
    /** UNTRANSLATED */
    col_assigned_entities: 'Assigned',
    /** UNTRANSLATED */
    user_counts: '{{count}} users',
    /** UNTRANSLATED */
    application_counts: '{{count}} apps',
    /** UNTRANSLATED */
    user_count: '{{count}} user',
    /** UNTRANSLATED */
    application_count: '{{count}} app',
    assign_permissions: 'Assigner des autorisations',
    create_role_title: 'Créer un rôle',
    create_role_description: "Créez et gérez des rôles pour vos applications. Les rôles contiennent des collections d'autorisations et peuvent être assignés aux utilisateurs.",
    create_role_button: 'Créer un rôle',
    role_created: 'Le rôle {{name}} a été créé avec succès.',
    search: 'Rechercher par nom de rôle, description ou ID',
    placeholder_title: 'Rôles',
    placeholder_description: "Les rôles sont un regroupement d'autorisations qui peuvent être assignées aux utilisateurs. Assurez-vous d'ajouter d'abord des autorisations avant de créer des rôles.",
};
export default Object.freeze(roles);
